import React, {useState} from 'react'
import styled from "styled-components";
import {FaTimes} from 'react-icons/fa';
import { Link } from "react-router-dom";
import ConnectButton from './connectButton';
import { Link as ScrollLink } from 'react-scroll';


const SidebarContainer = styled.aside`
    position: fixed;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    background: #4031B7;
    display: grid;
    align-items: center;
    top: 0;
    left: 0;
    transition:  0.3s ease-in-out;
    opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
    top: ${({ isOpen }) => (isOpen ? '0' : '-100%')}; 
`
const Icon = styled.div`
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
`
const CloseIcon = styled(FaTimes)`
    color: #fff;

    &:hover{
      color: red;
    }
`
const SidebarWrapper = styled.div`
    color:#fff;
    margin-top: -70px;
`

const SidebarLink = styled(ScrollLink)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    transition: 0.2s ease-in-out;
    color: #fff;
    cursor: pointer;

    &:hover {
        color: #4031B7;
    }
`
const SidebarLink2 = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    transition: 0.2s ease-in-out;
    color: #fff;
    cursor: pointer;

    &:hover {
        color: #4031B7;
    }
`
const SidebarDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`
const SidebarMenu = styled.ul`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 80px);
    text-align: center;
    margin-left: -20px;

    @media screen and (max-width: 480px) {
        grid-template-rows: repeat(6, 60px);
    }
`

const Sidebar = ({isOpen, toggle}) => {
  return (
    <>
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
            <CloseIcon />
        </Icon>
        <SidebarWrapper>
            <SidebarMenu>
                <SidebarLink2 to="/">Home</SidebarLink2>
                <SidebarLink to="testimonials" smooth={true} duration={500} spy={true} exact='true' offset={-120}>Testimonials</SidebarLink>
                <SidebarLink to="features" smooth={true} duration={500} spy={true} exact='true' offset={-180}>Features</SidebarLink>
                <SidebarLink2 to="/airdrops">Airdrops</SidebarLink2>
                <SidebarLink to="partners" smooth={true} duration={500} spy={true} exact='true' offset={-100}>Partners</SidebarLink>
                <SidebarLink to="contact" smooth={true} duration={500} spy={true} exact='true' offset={-180}>Contact</SidebarLink>
            </SidebarMenu>
        </SidebarWrapper>
    </SidebarContainer>
    </>
  )
}

export default Sidebar