import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

const testimonials = [
  {
    text: "When developing our Plasma MVP Implementation, our foremost priority was to establish robust security measures. Given the potential for our plasma implementations to manage substantial transaction volumes as a scalability solution, we sought the expertise of CryptoSolutions. Their extensive track record in auditing large-scale, mission-critical initiatives in the past and collaborating with CryptoSolutions is an unequivocal one.",
    author: "Evgeny Yurtaev",
    position: "CEO at Zerion",
  },
  {
    text: "During the audit process, SecureCryptoSolution provided excellent security standards for our contracts. They collaborated with our engineers to ensure the safety of our smart contracts, and their recommendations helped enhance the quality of our code. Overall, their efforts helped us achieve the highest level of security for our contracts.",
    author: "Tang Hongbo",
    position: "CEO DeBank",
  },
  {
    text: "As we contribute to mainstream blockchain development alongside the talented Flow community, SecureCryptoSolution brings its renowned security and code review expertise to further bolster the ecosystem. SecureCryptoSolution is poised to play a vital role in Flow's continued growth and success.",
    author: "Skirmantas Januskas",
    position: "CEO at DappRadar",
  },
];

const TestimonialCarouselContainer = styled.div`
  position: relative;
  max-width: 85%;
  margin: auto;
  padding: 20px;
`;
const TestimonialTextContainer = styled.div`
  position: relative;
  max-width: 85%;
  margin: auto;
  padding: 20px;
  @media (min-width: 768px) {
    height: 200px;
  }
`;

const TestimonialText = styled.div`
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 20px;
  text-align: center;
  padding: 20px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const AuthorInfo = styled.div`
  font-style: italic;
  text-align: center;
`;

const AuthorName = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;

const AuthorPosition = styled.div`
  color: #666;
`;

const Controls = styled.div`
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  @media (max-width: 768px) {
    width: 90%
  }
`;

const ControlButton = styled.button`
  background-color: #0492C2;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
`;

const IndicatorContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const IndicatorDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => (props.active ? '#0492C2' : '#ddd')};
  margin: 0 5px;
  cursor: pointer;
`;

const TestimonialCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  const goToPrevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
  };

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
  };

  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      // Remove '#' from the hash
      const sectionId = hash.substring(1);
      const element = document.getElementById(sectionId);

      if (element) {
        // Scroll to the corresponding section
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [hash]);

  return (
    <TestimonialCarouselContainer id="testimonials">
      <h1>Testimonials</h1>
      <Controls>
        <ControlButton onClick={goToPrevSlide}>&lt;</ControlButton>
        <ControlButton onClick={goToNextSlide}>&gt;</ControlButton>
      </Controls>
      <TestimonialTextContainer>
        <TestimonialText>{testimonials[currentIndex].text}</TestimonialText>
        <AuthorInfo>
            <AuthorName>{testimonials[currentIndex].author}</AuthorName>
            <AuthorPosition>{testimonials[currentIndex].position}</AuthorPosition>
        </AuthorInfo>
      </TestimonialTextContainer>
      <IndicatorContainer>
        {testimonials.map((_, index) => (
          <IndicatorDot
            key={index}
            active={index === currentIndex}
            onClick={() => goToSlide(index)}
          />
        ))}
      </IndicatorContainer>
    </TestimonialCarouselContainer>
  );
};

export default TestimonialCarousel;
