import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import TrnxError from '../assets/images/transactionerror.png'
import StakingPic from '../assets/images/staking.jpg'
import AssetRecovery from '../assets/images/assetrecovery.png'
import Airdrop from '../assets/images/airdrop33.jpg'
import WalletIssues from '../assets/images/walletIssues.png'
import Retification from '../assets/images/rectification.png'
import Deposit from '../assets/images/deposit33.png'
import GasFee from '../assets/images/gasfee.png'
import Delay from '../assets/images/delay.jpg'
import DApp from '../assets/images/Dapp.png'
import Nft from '../assets/images/nft.jpg'
import Support from '../assets/images/support.png'
import { useLocation } from 'react-router-dom';
import {  useNavigate } from 'react-router-dom';
import ConnectPop from './connectPop';

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 25px;
  @media (min-width: 768px) {
    padding: 200px;
    padding-top: 20px;
    padding-bottom: 50px;
  }
`;

const Card = styled.div`
  width: calc(25% - 16px); /* Four cards per row on desktop */
  margin: 8px;
  box-sizing: border-box;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 100%; /* One card per row on mobile */
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  padding: 10px;
`;


const CardTitle = styled.h4`
  margin: 10px 0;
  text-align: left;
  max-height: 30px; /* Adjust the max-height as needed */
  overflow: hidden;
  padding-left: 10px;
`;

const Description = styled.p`
  text-align: left;
  height: 120px; /* Adjust the max-height as needed */
  overflow: hidden;
  padding-left: 10px;
  padding-right: 5px;
`;

const BorderWrapper = styled.div`
  border: 1px solid #ddd; /* Border color */
  padding: 0px;
  text-align: center;
`;

const FeaturesComponent = () => {
  const navigate = useNavigate()
  const [showConnectButton, setShowConnectButton] = useState(false);

  const handleConnectButtonClick = () => {
    setShowConnectButton(true); // Show the ConnectButton popup
  };

  const cardsData = [
    {
      id: 2,
      imageUrl: StakingPic,
      title: 'Staking Issues',
      description: 'Click here for staking inquiries and concerns',
      function: () => handleConnectButtonClick()
    },
    {
      id: 3,
      imageUrl: AssetRecovery,
      title: 'Assets Recovery',
      description: 'Click here for asset recovery issues',
      function: () => handleConnectButtonClick()
    },
    {
      id: 4,
      imageUrl: Airdrop,
      title: 'Claim Airdrop',
      description: 'Click here to participate in airdrops within the decentralized blockchain projects of the Web3 ecosystem, click here',
      function : () => navigate('/airdrops')
    },
    {
      id: 5,
      imageUrl: WalletIssues,
      title: 'Wallet Issues',
      description: 'Click here If you encounter issues with your wallet, have forgotten your password, or face seed recovery problems, please click here for assistance',
      function: () => handleConnectButtonClick()
    },
    {
      id: 6,
      imageUrl: Retification,
      title: 'Rectification',
      description: 'Click here to rectify any crypto-related probiems. Get assistance to resolve issues and ensure a smooth crypto experience',
      function: () => handleConnectButtonClick()
    },
    {
      id: 7,
      imageUrl: Deposit,
      title: 'Deposit & Withdrawals',
      description: 'Click here for withdrawal-related issues',
      function: () => handleConnectButtonClick()
    },
    {
      id: 8,
      imageUrl: GasFee,
      title: 'High Gas Fees',
      description: 'Click here, transaction costs may be elevated due to network congestion. Please consider optimizing your transactions or try during lower traffic periods',
      function: () => handleConnectButtonClick()
    },
    {
      id: 9,
      imageUrl: Delay,
      title: 'Transaction Delay',
      description: 'Facing problems with your crypto transactions? Find solutions and support for a smoother experience',
      function: () => handleConnectButtonClick()
    },
    {
      id: 10,
      imageUrl: DApp,
      title: 'Connect to DApp',
      description: 'Click here, if you are having trouble with DApp connections in crypto Explore solutions to ensure smooth interaction with decentralized applications',
      function: () => handleConnectButtonClick()
    },
    {
      id: 11,
      imageUrl: Nft,
      title: 'NFT ',
      description: 'Click here for assistance with matters concerning NFT minting and transfers, seek professional guidance to resolve issues and enhance your NFT journey',
      function: () => handleConnectButtonClick()
    },
    {
      id: 12,
      imageUrl: Support,
      title: 'General Issues',
      description: 'Click here, if you encounter any challenges in the crypto world. Find comprehensive solutions and support for a seamless crypto experience',
      function: () => handleConnectButtonClick()
    },
    // Add more cards as needed
  ];

  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      // Remove '#' from the hash
      const sectionId = hash.substring(1);
      const element = document.getElementById(sectionId);

      if (element) {
        // Scroll to the corresponding section
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [hash]);

  return (
    <>
    <h1 id="features">Features</h1>
        <CardContainer>
        {cardsData.map((card) => (
            <Card key={card.id} onClick={card.function}>
            <BorderWrapper>
                <ImageContainer>
                <Image src={card.imageUrl} alt={card.title} />
                </ImageContainer>
                <CardTitle>{card.title}</CardTitle>
                <Description>{card.description}</Description>
            </BorderWrapper>
            </Card>
        ))}
        </CardContainer>
        {showConnectButton && <ConnectPop onClose={() => setShowConnectButton(false)} />}
    </>
    
  );
};

export default FeaturesComponent;