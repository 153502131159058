import React, {useState} from 'react'
import Nav from '../components/nav'
import { Helmet } from 'react-helmet';
import Sidebar from '../components/sidebar';
import Hero from '../components/hero';
import Footer from '../components/footer';
import CardComponent from '../components/card';
import Partners from '../components/partners';
import TestimonialCarousel from '../components/Testimonial';
import FeaturesComponent from '../components/features';
import MessageUs from '../components/MessageUs';
import { Element } from 'react-scroll';


const Home = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {
    setIsOpen(!isOpen);
  };
  const testimonials = [
  {
    text: "When developing our Plasma MVP Implementation, our foremost priority was to establish robust security measures. Given the potential for our plasma implementations to manage substantial transaction volumes as a scalability solution, we sought the expertise of CryptoSolutions. Their extensive track record in auditing large-scale, mission-critical initiatives in the past and collaborating with CryptoSolutions is an unequivocal one.",
    author: "Evgeny Yurtaev",
    position: "CEO at Zerion",
  },
  {
    text: "During the audit process, SecureCryptoSolution provided excellent security standards for our contracts. They collaborated with our engineers to ensure the safety of our smart contracts, and their recommendations helped enhance the quality of our code. Overall, their efforts helped us achieve the highest level of security for our contracts.",
    author: "Tang Hongbo",
    position: "CEO DeBank",
  },
  {
    text: "As we contribute to mainstream blockchain development alongside the talented Flow community, SecureCryptoSolution brings its renowned security and code review expertise to further bolster the ecosystem. SecureCryptoSolution is poised to play a vital role in Flow's continued growth and success.",
    author: "Skirmantas Januskas",
    position: "CEO at DappRadar",
  },
];
  return (
    <>
    <Helmet>
        <title>CryptoSolutions - Home</title>
        <meta name="description" content="Securing the future of web3" />
    </Helmet>
        <Nav toggle={toggle}/>
       <Sidebar isOpen={isOpen} toggle={toggle} />
        <Hero />
        <CardComponent />
        <Partners  />
        <TestimonialCarousel />
        <FeaturesComponent/>
        <MessageUs/>
        <Footer />
    </>
  )
}

export default Home