import React, {useState} from 'react'
import { Helmet } from 'react-helmet';
import Sidebar from '../components/sidebar';
import Footer from '../components/footer';
import Hero2 from '../components/airdropsHero';
import Nav2 from '../components/nav2';


const Airdrops = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {
    setIsOpen(!isOpen);
  };
  

  return (
    <>
    <Helmet>
        <title>CryptoSolutions - Airdrops</title>
        <meta name="description" content="Securing the future of web3" />
    </Helmet>
        <Nav2 toggle={toggle}/>
        <Sidebar isOpen={isOpen} toggle={toggle} /> 
          <Hero2 />
          <Footer />        
    </>
  )
}

export default Airdrops