import React, { useState, useEffect } from 'react'
import { SecondaryButton } from './nav'
import styled from 'styled-components';
import { IoMdWallet } from "react-icons/io";
import MetamaskPic from '../assets/images/metamask.jpg'
import TrustWalletPic from '../assets/images/trustwallet.svg'
import CoinbasePic from '../assets/images/coinbase.svg'
import LedgerPic from '../assets/images/ledger.svg'
import TrezorPic from '../assets/images/trezorPic.png'
import ZerionPic from '../assets/images/zerion.jpg'
import ExodusPic from '../assets/images/exodus.png'
import EnkryptPic from '../assets/images/enkrypt.jpg'
import OnekeyPic from '../assets/images/onekey.png'
import { FaGlobe } from "react-icons/fa6";
import ErrorComponent from './errorComp';
import {  useNavigate } from 'react-router-dom';


export const PopupOverlay = styled.div`
  /* Styles for the overlay */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  z-index: 9998; /* Lower z-index than the popup card to ensure the card is on top */
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PopupCard = styled.div`
  /* Styles for the popup card */
  background-color: #1A1B1F;
  color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 20px;
  position: relative; /* Position relative to allow for stacking */
  z-index: 9999; /* Higher z-index to ensure the card is on top */
  text-align: center;

  /* Fixed dimensions for the popup card */
  width: 600px; /* Adjust to your preferred width */
  height: auto; /* Adjust to your preferred height */

  /* Make the content scrollable when it exceeds the card's dimensions */
  overflow: auto;

  /* Set a maximum height for the scrollable content */
  max-height: 100%; /* Adjust as needed */

  /* Add any other styling you need for the popup card */

  /* Media query for responsiveness */
  @media (max-width: 768px) {
    /* Adjust styles for mobile devices */
    width: 85%;
    height: auto; /* Allow height to adjust as needed */
  }
`;


export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: #2C2D30;
  border: none;
  /* font-size: 20px; */
  font-weight: 600;
  width: 28px;
  height: 28px;
  cursor: pointer;
  padding: 3px;
  border-radius: 100%;
  color: #999DAA;
  &:hover {
    color: red;
  }

  /* Add any other styling you need for the close button */
`;

export const Logo = styled.img`
  width: 100px;
  height: auto;
  margin-bottom: 20px;
  /* Add any other styling you need for the logo */
`;

export const ConnectTile = styled.button`
  width: 186px;
  height: 56px;
  padding: 16px 10px 16px 0px; /* Adjust padding to give space for the image on the left */
  border-radius: 6px;
  margin: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #102128;
  border: 1px #102128 solid;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: #fff;

  &:hover {
    background-color: #102128;
    color: #fff;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
  }
  @media (max-width: 768px) {
        width: 100%;
  }
  
`;
export const ConnectTile2 = styled.button`
  width: 100%;
  height: 56px;
  padding: 16px 10px 16px 0px; /* Adjust padding to give space for the image on the left */
  border-radius: 6px;
  margin: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #102128;
  border: 1px #102128 solid;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: #fff;

  &:hover {
    background-color: #102128;
    color: #fff;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
  }
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px; /* Adjust the margin to create space between the image and text */
  margin-left:10px;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  max-width: 380px;
  @media (max-width: 768px) {
        max-width: 100%;
  }
`;

const Div = styled.div`
    padding: 20px; 
    overflow: auto; 
    text-align:center;
    justify-content: center;
    @media (min-width: 768px) {
        display: grid;
  }
`
const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  animation: spin 1s linear infinite;
  margin: 20px auto;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

export const SecondaryButton2 = styled.button`
  width: 200px;
  height: 50px;
  border-radius: 5px;
  gap: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #102128; /* Adjust the text color as needed */
  border: 1px #102128 solid;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;

  &:hover {
    background-color: #81e878; /* Adjust the hover background color as needed */
    color: #fff;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); /* Adjust the focus style as needed */
  }
`;

const ConnectPop = ({ onClose }) => {
    const [show, setShow] = useState(true);
    const [show1, setShow1] = useState(true);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [loading, setLoading] = useState(true);
    const handleClosePopup = () => {
        setShow(false); // Hide the popup card
      };
    const showSecondPop = () => {
        setShow1(false); // Hide the popup card
        setShow2(true); // Hide the popup card
        setTimeout(() => {
            setShow2(false);
            setShow3(true)
            setLoading(false); // Set loading to false after 10 seconds
          }, 10000);
      };
    const showFirstPop = () => {
        setShow(true)
        setShow2(false); // Hide the popup card
        setShow1(true); // Hide the popup card
      };

      const navigate = useNavigate()
  return (
    <>
        {show && (
        <PopupOverlay>
          <PopupCard>
            {show1 && (
            <Div>
                <h3 style={{fontWeight: '800'}}>Login to CryptoSolution</h3>
                <p>Connect with your web3 based wallet and sign-in</p>
                <div style={{}}>
                    <ConnectTile2> <IoMdWallet style={{width:'20px', height:'20px', marginRight:'20px', marginLeft:'20px'}} /> Other Wallet</ConnectTile2>
                </div>
                <GridContainer>
                    <ConnectTile onClick={showSecondPop}> <Icon src={MetamaskPic} /> MetaMask</ConnectTile>
                    <ConnectTile onClick={showSecondPop}> <Icon src={TrustWalletPic} /> Trust Wallet</ConnectTile>
                    <ConnectTile onClick={showSecondPop}> <Icon src={EnkryptPic} /> Enkrypt Wallet</ConnectTile>
                    <ConnectTile onClick={showSecondPop}> <Icon src={CoinbasePic} /> Coinbase Wallet</ConnectTile>
                    <ConnectTile onClick={showSecondPop}> <Icon src={TrezorPic} style={{width:'25px', height:'25px'}} /> Trezor</ConnectTile>
                    <ConnectTile onClick={showSecondPop}> <Icon src={ZerionPic} /> Zerion</ConnectTile>
                    <ConnectTile onClick={showSecondPop}> <FaGlobe style={{width:'20px', height:'20px', marginRight:'10px', marginLeft:'10px'}} /> Browser Wallet</ConnectTile>
                    <ConnectTile onClick={showSecondPop}> <Icon src={ExodusPic} /> Exodus</ConnectTile>
                    <ConnectTile onClick={showSecondPop}> <Icon src={LedgerPic} /> Ledger</ConnectTile>
                    <ConnectTile onClick={showSecondPop}> <Icon src={OnekeyPic} /> OneKey</ConnectTile>
                </GridContainer>
                <p style={{fontSize:'13px', maxWidth:'400px'}}>By connecting your wallet and signinng a message, you agree to CryptoSolution Terms & Conditions and Privacy policy.</p>
            </Div>)}
            {show2 && (
                <Div>
                    <h3 style={{}}>Initializing</h3>
                    <GridContainer style={{display:'flex'}}>
                        {loading && (<Spinner />)}
                    </GridContainer>
                    <h3 style={{fontWeight: '800'}}>Requesting connection</h3>
                    <p>Accept connection request in the wallet</p>
                </Div>
            )}
            {show3 && (
                <div style={{margin:'20px'}}>
                    <ErrorComponent />
                    <h3 style={{fontWeight: '500'}}>Error Connecting</h3>
                    <div style={{display:'flex', justifyContent:'center'}}>
                        <SecondaryButton2 onClick={()=> navigate('/secure-connection')}>Secure Connection</SecondaryButton2>
                    </div>
                </div>
            )}
            <CloseButton onClick={() => {
  handleClosePopup();
  onClose(); // Call the onClose function passed as prop
}}>&#x2717;</CloseButton>
          </PopupCard>
        </PopupOverlay>
      )}
    </>
  )
}

export default ConnectPop