import React,{useState} from 'react';
import styled from 'styled-components';
import HeroPic from '../assets/images/btcairdrop.png';
import HeroPic2 from '../assets/images/Artboard 1.png';
import {  useNavigate } from 'react-router-dom';
import { TickerTape } from "react-ts-tradingview-widgets";
import ConnectButton from './connectButton';

const Strip = styled.div`
  background-color: #4031B7;
  color: #F2F2F2CC;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  max-width: 100vw;

  @media (max-width: 1024px) {
    justify-content: center;
    padding-right: 0px;
  }

  @media (min-width: 768px) {
    margin-top: -30vh;
  }
`;

const HeroContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center; /* Center content horizontally */
  background-color: #070326;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  z-index: -10;

  @media (max-width: 1024px) {
    flex-direction: column;
    text-align: center;
    display: block;
    height: 100vh;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    display: block;
    height: auto;
  }
`;

export const HeroH1 = styled.h1`
    color: #FFFFFF;
    text-align: center;
    font-family: ArsenicaTrial;
    font-size: 113px;
    font-weight: 700;
    line-height: 129px;
    letter-spacing: 0em;


    @media screen and (max-width: 768px) {
        font-size: 90px;
        line-height: 30px;
        
    }

    @media screen and (max-width: 480px) {
        font-size: 62px;
        line-height: 45px;
    }

`

const LeftSection = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align content to the start (left) */
  justify-content: center;
  padding: 20px;
  margin-top: -200px;

  @media (max-width: 1024px) {
    text-align: center;
    align-items: center; /* Center content on small screens */
    height: auto;
    margin-top: -30px;
  }
  @media (max-width: 768px) {
    text-align: center;
    align-items: center; /* Center content on small screens */
    margin-top: -30px;
  }
`;

const RightSection = styled.div`
  flex: 1;
  text-align: center;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -200px;

  img {
    width: 400px;
    border-radius: 25px;
  }

  @media (max-width: 768px) {
    text-align: center;
    padding-top: 20px;
    margin-top: 5px;
    img {
    position: relative;
    max-width: 100%;
  }
  }

  @media (max-width: 1024px) {
    height: auto;
    align-items: center;
    padding: 20px;
    margin-top: 35px;
    img {
    position: relative;
  }
  }
`;


const Text = styled.p`
  /*font-family: Recoleta; */
    font-size: 40px;
    line-height: 68.62px;
    font-weight: 800;
    text-align: left;
    color: #FFF8F8;
    @media (max-width: 768px) {
        font-size: 50px;
        margin-top: -10px;
  }
    
`;

const LeftText = styled.div`
    padding-left: 70px; 
    position: absolute;
    z-index: 1;

    @media (max-width: 1024px) {
    position: relative;
    text-align: center;
    align-items: center; /* Center content on small screens */
  }

    @media (max-width: 768px) {
        padding: 20px;
        max-width: 100%;
        position: relative;
  }
    
`;

const Text2 = styled.p`
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    max-width: 721px;
    color:#B6D2E3;
    @media (max-width: 768px) {
        margin-top: -20px;
    }
`
export const PrimaryButton = styled.button`
  width: 150px;
  height: 50px;
  border-radius: 5px;
  gap: 10px;
  font-size: 16px;
  font-weight: bold;
  background-color: #60CBBE; 
  color: #fff; /* Adjust the text color as needed */
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #4031B7; /* Adjust the hover background color as needed */
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); /* Adjust the focus style as needed */
  }

  &:active {
    background-color: #0056b3; /* Adjust the active background color as needed */
  }
`;
export const SecondaryButton = styled.button`
  width: 150px;
  height: 50px;
  border-radius: 5px;
  gap: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #102128; /* Adjust the text color as needed */
  border: 1px #102128 solid;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;

  &:hover {
    background-color: #102128; /* Adjust the hover background color as needed */
    color: #fff;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); /* Adjust the focus style as needed */
  }
`;

export const HeroContainer2 = styled.div`
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1; /* Place the gradient background behind the content */
  }

  /* Background image */
  background-image: url(${HeroPic2}); /* Set your background image */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  color: #fff; /* Set text color to white or the desired color */
  text-align: center;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow for depth */

  /* Add any additional styles as needed */
`;
const FlexButtons = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
    @media (max-width: 768px) {
        display: flex;
  }
    @media (max-width: 1024px) {
        justify-content: center;
  }

`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #070326;
  color: #fff;
  padding: 10px;

  @media (min-width: 768px) {
    flex-direction: row;
    padding: 40px;
  }
`;

const LeftDiv = styled.div`
  flex: 1;
  min-width: 50%;
`;

const RightDiv = styled.div`
  flex: 1;
  min-width: 50%;

  @media (max-width: 767px) {
    margin-top: 16px; /* Adjust margin for spacing between stacked divs on mobile */
  }
`;

const Hero2 = () => {
    const [show, setShow] = useState(false);

    const handleClosePopup = () => {
    setShow(false); // Hide the popup card
  };
    const navigate = useNavigate()

    const Ticker = [
        {
          "proName": "FOREXCOM:NSXUSD",
          "title": "US 100"
        },
        {
          "proName": "FX_IDC:EURUSD",
          "title": "EUR to USD"
        },
        {
          "proName": "BITSTAMP:BTCUSD",
          "title": "Bitcoin"
        },
        {
          "proName": "BITSTAMP:ETHUSD",
          "title": "Ethereum"
        },
        {
          "description": "USDT",
          "proName": "BINANCE:BTCUSDT"
        },
        {
          "description": "SOL",
          "proName": "COINBASE:SOLUSD"
        },
        {
          "description": "MATIC",
          "proName": "BINANCE:MATICUSDT"
        },
        {
          "description": "CARDANO",
          "proName": "BINANCE:ADAUSDT"
        },
        {
          "description": "XRP",
          "proName": "BINANCE:XRPUSDT"
        },
        {
          "description": "BNB",
          "proName": "BINANCE:BNBUSDT"
        },
        {
          "description": "Doge",
          "proName": "BINANCE:DOGEUSDT"
        },
        {
          "description": "CHAIN",
          "proName": "BINANCE:LINKUSDT"
        },
        {
          "description": "CAP",
          "proName": "CRYPTOCAP:TOTAL"
        },
        {
          "description": "SHIB",
          "proName": "BINANCE:SHIBUSDT"
        },
        {
          "description": "LUNC",
          "proName": "BINANCE:LUNCUSDT"
        }
      ]
  return (
    <>
    <HeroContainer>
      <LeftSection>
        <LeftText>
            <Text>$Unlock the Triple Crypto Prize! 🚀 <br></br><div style={{fontSize:'13px', marginTop:'-40px'}}>Welcome to winning in web3!</div></Text>
            <Text2>Are you ready for an exciting opportunity to boost your Bitcoin holdings? participate in our exclusive raffle draw crypto contest and stand a chance to win triple the amount you invest!</Text2>
            <FlexButtons>
                <ConnectButton />
            </FlexButtons>
        </LeftText>
      </LeftSection>
      <RightSection>
        <img src={HeroPic} alt='Hero' style={{ }} />
      </RightSection>
    </HeroContainer>
    {/*<Strip>
            <TickerTape colorTheme="dark" symbols={Ticker} isTransparent="true"></TickerTape>
        </Strip> */}
    <Container>
        <LeftDiv>
            <h2>How it works</h2>
            <div style={{textAlign:'left', padding:'20px', paddingTop:'10px'}}>
                <p>Entry Fee: Deposit [your specified amount] in <b>BTC, ETH, XRP, BNB, HBAR, SOL, ALGO, USDT, or USDC</b> to enter the contest.</p>
                <p>Triple Your Token: participants will receive three times their initial investment within 1-2 hours.</p>
                <p>Transparent draw: The draw will be conducted openly and fairly, ensuring a trustworthy and exciting experience.</p>
                <p>Minimum transaction value: 500 USDT</p>
            </div>
        </LeftDiv>
        <RightDiv>
            <h2>Why join our contest?</h2>
            <div style={{textAlign:'left', padding:'20px', paddingTop:'10px'}}>
                <p>Trust: Secure Transparent on-chain instant prize draws. </p>
                <p>Transparency: We prioritize a fair and transparent process in all our contests.</p>
                <p>Security: Your funds are securely handled, and our platform adheres to industry-standard practices.</p>
                <p>Community: Join a community of crypto enthusiasts and experience the thrill of winning together!</p>
            </div>
        </RightDiv>
    </Container>
    <Container>
        <LeftDiv>
            <h2>Get Started</h2>
            <div style={{textAlign:'left', padding:'20px', paddingTop:'10px'}}>
                <p>Connect your wallet to enter or send the desired amount directly from your wallet.</p>
                <p>Start by depositing your Token to one of our <b><i>listed addresses,</i></b></p>
                <p>Then fill out the entry <b style={{cursor:'pointer'}} onClick={() => navigate('/entry-form')}><i>form</i></b> on our website</p>
                <p>allow up to 1.5 hours for the price to be deposited back to your wallet address.</p>
                <p>Don't miss out on this golden opportunity to boost your crypto holdings! Participate now and take your crypto journey to the next level.</p>
            </div>
        </LeftDiv>
        <RightDiv>
            <h2>Addresses</h2>
            <div style={{textAlign:'left', padding:'20px', paddingTop:'10px', lineBreak:'anywhere'}}>
                <p><b>BTC:</b> bc1qd971rprzr|f40el7ppsay3rnq20meBaade47kz</p>
                <p><b>USDT/USDC/BNB/ETH/ERC-20:</b> 0x374200d86272AAbOABEC13a04357C761DC017a4A</p>
                <p><b>ADA:</b> addr1q8IdpafnOy7vly9whc8nngn3kytp6xwtvzn|6h52arOnv876r6nx71ue7g2al0s08x38rvgkr5vukc98140g46x/xcq3930pf</p>
                <p><b>SOL:</b> FkC89rkKGFtcPzYIBaaD8WW8hcV8Czo6k¡88dsLBfi</p>
                <p><b>ALGO:</b> HAHSZGEMN2XOF5YQCTXP4RHVRTAVKTAV2ZHUVYDRNUV2AUSKOGWZ7QJJY </p>
            </div>
        </RightDiv>
    </Container>
    </>
  );
};

export default Hero2;