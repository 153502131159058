import React, { useState } from 'react';
import './NewsletterSubscription.css'; // Import your CSS file for styling

const NewsletterSubscription = () => {
  const [email, setEmail] = useState('');
  const [subscribed, setSubscribed] = useState(false);

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubscription = (event) => {
    event.preventDefault();
    // Add your subscription logic here (e.g., send the email to a server, update a database, etc.)
    console.log(`Subscribing email: ${email}`);
    setSubscribed(true);
  };

  return (
    <div className="newsletter-container">
      {subscribed ? (
        <div className="thank-you-message">
          <p>Thank you for subscribing!</p>
        </div>
      ) : (
        <form onSubmit={handleSubscription} className="subscription-form">
          <div className="input-container">
            <input
              type="email"
              value={email}
              onChange={handleInputChange}
              placeholder="Your email address"
              required
              className="email-input"
            />
            <button type="submit" className="subscribe-button">
              Subscribe
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default NewsletterSubscription;
