import React from 'react'
import styled from 'styled-components';
import Logo from '../assets/images/logo.png'
import {BiMap} from 'react-icons/bi'
import {PiEnvelopeLight} from 'react-icons/pi'
import {FiPhoneCall} from 'react-icons/fi'
import {BsFacebook} from 'react-icons/bs'
import {ImLinkedin} from 'react-icons/im'
import {FaXTwitter} from 'react-icons/fa6'
import {AiFillInstagram} from 'react-icons/ai'
import { Link as Linkk } from "react-router-dom";
import NewsletterSubscription from './newsletter';


const FooterContainer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #F4F4F4;
  height: auto;
  padding: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const LeftSection = styled.div`
  height: 100%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const RightSection = styled.div`
  height: 100%;
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start; /* Align headers to the top */

  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
    padding-top: 20px;
  }
  @media (max-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 3 columns on desktop */
  }
`;

const LogoColumn = styled.div`
  flex: 1;
  min-width: 200px;
  margin-right: 20px;
  margin-left: 60px;
  text-align: left;
  @media (max-width: 768px) {
    margin-left: -25px;
  }
  @media (max-width: 1024px) {
    margin-left: 0px;
  }
`;

const LogoImage = styled.img`
  width: 255px;
  height: auto;
  margin-bottom: -40px;
`;

const Column = styled.div`
  flex: 1;
  margin-right: 20px; /* Add spacing between columns */
  text-align: left;

  &:last-child {
    margin-right: 0; /* Remove spacing for the last column */
  }

  @media (max-width: 768px) {
    margin-right: 0; /* Remove spacing on smaller screens */
    margin-bottom: 20px; /* Add spacing between columns on smaller screens */
  }
  @media (min-width: 1024px) {
    min-width: 200px;
  }
`;

const Title = styled.h4`
  font-size: 18px;
  margin-bottom: 10px;
`;

const Links = styled.ul`
  list-style: none;
  padding: 0;
`;

const Link = styled.li`
  margin-bottom: 20px;
  color: #102128;

  &:hover{
    color: #4031B7;
  }
`;
const Link2 = styled.li`
  margin-bottom: 20px;
  color: #102128;
  font-size: 16px;
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: 12px;
  }

  &:hover{
    color: #4031B7;
  }
`;

const SocialIcons = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  @media (max-width: 768px) {
    display: block;
  }
`;

const SocialIcon = styled.a`
  margin-right: 20px;
  text-decoration: none;
  color: #4031B7;
  @media (max-width: 768px) {
    margin-right: 0px;
    margin-left: 20px;
  }
  &:hover {
    color: #102128;
  }
`;

const IconStyle = { color: '#BCBCBC' };

const Footer = () => {
  return (
    <>
        <footer style={{bottom:'0'}}>
            <FooterContainer>
                <LeftSection>
                    <LogoColumn>
                        <LogoImage src={Logo} style={{marginLeft:'-45px'}} alt='cryptoSolutions' />
                        <p style={{fontWeight:'bold'}}>STAY UP TO DATE</p>
                        <NewsletterSubscription />
                        <Links>
                        <Linkk to='mailto:info@csolution.co'  style={{textDecoration:'none'}}><Link><PiEnvelopeLight style={IconStyle}/> info@csolution.co</Link></Linkk>
                        <Link2>0x374200d86272AAb0ABEC13a04357C761DC017a4A</Link2>
                        </Links>
                    </LogoColumn>
                    {/*<SocialIcons>
                        <SocialIcon href={"https://facebook.com"}>
                        <BsFacebook fontSize={22}/>
                        </SocialIcon>
                        <SocialIcon href={"https://twitter.com/"}>
                        <AiFillInstagram fontSize={26}/>
                        </SocialIcon>
                        <SocialIcon href={"https://www.instagram.com/"}>
                        <FaXTwitter fontSize={22}/>
                        </SocialIcon>
                        <SocialIcon href={"https://ng.linkedin.com"}>
                        <ImLinkedin fontSize={22}/>
                        </SocialIcon>
                    </SocialIcons>*/}
                </LeftSection>
                <RightSection>
                    <Column>
                        <Title>MENU</Title>
                        <Links>
                        <Linkk to='/bespoke' style={{textDecoration: 'none'}}><Link>Bespoke</Link></Linkk>
                        <Link>About</Link>
                        <Link>Features</Link>
                        <Link>Partners</Link>
                        <Link>Contact Us</Link>
                        </Links>
                    </Column>
                    <Column>
                        <Title>LINKS</Title>
                        <Links>
                        <Linkk to='/about' style={{textDecoration: 'none'}}><Link>Terms</Link></Linkk>
                        <Linkk to='https://blog.gregdanielclothings.com' style={{textDecoration: 'none'}}><Link>Privacy</Link></Linkk>
                        <Linkk to='/contact' style={{textDecoration: 'none'}}><Link>Disclaimer</Link></Linkk>
                        </Links>
                    </Column>
                    <Column>
                        <Title>SOCIAL</Title>
                        <Links>
                        <Linkk to='/faq'  style={{textDecoration: 'none'}}><Link>FAQs</Link></Linkk>
                        <Linkk to='/terms' style={{textDecoration: 'none'}}><Link>Terms & Conditions</Link></Linkk>
                        <Linkk to='/return-policy' style={{textDecoration: 'none'}}><Link>Return Policy</Link></Linkk>
                        </Links>
                    </Column>
                </RightSection>
            </FooterContainer>
            <div style={{textAlign:'center', color:'#6C6C6C',background:'#E7E7E7', fontFamily: 'Lato',fontSize: '16px',fontWeight: 500, lineHeight: '19px',letterSpacing: '0em', padding:'20px'}}>
                Copyright © 2021 - {new Date().getFullYear()}. CryptoSolutions, Inc.
            </div>
        </footer>
    </>
  )
}

export default Footer