import React from 'react';
import styled from 'styled-components';
import Secure600 from '../assets/images/secure600m.jpg'
import Remote from '../assets/images/remotefirst.jpg'
import Customerz from '../assets/images/customers.jpg'
import SecureNetwork from '../assets/images/securenetwork.jpg'

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 25px;
  @media (min-width: 768px) {
    padding: 55px;
  }
`;

const Card = styled.div`
  width: calc(25% - 16px); /* Four cards per row on desktop */
  margin: 8px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    width: 100%; /* One card per row on mobile */
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const Image = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
`;

const CardTitle = styled.h2`
  margin: 10px 0;
  text-align: center;
  max-height: 30px; /* Adjust the max-height as needed */
  overflow: hidden;
`;

const Description = styled.p`
  text-align: center;
  max-height: 40px; /* Adjust the max-height as needed */
  overflow: hidden;
`;

const BorderWrapper = styled.div`
  border: 1px solid #ddd; /* Border color */
  padding: 10px;
  text-align: center;
`;

const CardComponent = () => {
  const cardsData = [
    {
      id: 1,
      imageUrl: Secure600,
      title: '$600M+',
      description: 'Secured by CryptoSolutions',
    },
    {
      id: 2,
      imageUrl: Remote,
      title: 'Remote 1st Company',
      description: 'Legal Entities in US, EU and Dubai',
    },
    {
      id: 3,
      imageUrl: Customerz,
      title: '80+',
      description: 'Innovative customers',
    },
    {
      id: 4,
      imageUrl: SecureNetwork,
      title: 'Security Network',
      description: 'Mature and decentralized',
    },
    // Add more cards as needed
  ];

  return (
    <CardContainer>
      {cardsData.map((card) => (
        <Card key={card.id}>
          <BorderWrapper>
            <ImageContainer>
              <Image src={card.imageUrl} alt={card.title} />
            </ImageContainer>
            <CardTitle>{card.title}</CardTitle>
            <Description>{card.description}</Description>
          </BorderWrapper>
        </Card>
      ))}
    </CardContainer>
  );
};

export default CardComponent;