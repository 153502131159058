import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FaExclamationCircle } from 'react-icons/fa';

const shakeAnimation = keyframes`
  0%, 100% {
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-10px);
  }
  20%, 40%, 60%, 80% {
    transform: translateX(10px);
  }
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ff4d4f; /* Error color */
`;

const ErrorIcon = styled(FaExclamationCircle)`
  font-size: 56px;
  padding: 20px;
  animation: ${shakeAnimation} 0.8s ease-in-out; /* Apply the shake animation */
`;

const ErrorComponent = () => {
  return (
    <ErrorContainer>
      <ErrorIcon />
      {/* Add additional content or actions if needed */}
    </ErrorContainer>
  );
};

export default ErrorComponent;
