import React,{useState} from 'react';
import styled from 'styled-components';
import HeroPic from '../assets/images/globe.gif';
import HeroPic3 from '../assets/images/blockSecurity.jpg';
import HeroPic2 from '../assets/images/Artboard 1.png';
import {  useNavigate } from 'react-router-dom';
import ConnectButton2 from './connectButton2';


const HeroContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center; /* Center content horizontally */
  background-color: #2615AC;
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  @media (max-width: 1024px) {
    flex-direction: column;
    text-align: center;
    display: block;
    height: 100vh;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    display: block;
    height: auto;
  }
`;

export const HeroH1 = styled.h1`
    color: #FFFFFF;
    text-align: center;
    font-family: ArsenicaTrial;
    font-size: 113px;
    font-weight: 700;
    line-height: 129px;
    letter-spacing: 0em;


    @media screen and (max-width: 768px) {
        font-size: 90px;
        line-height: 30px;
        
    }

    @media screen and (max-width: 480px) {
        font-size: 62px;
        line-height: 45px;
    }

`

const LeftSection = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align content to the start (left) */
  justify-content: center;
  padding: 20px;
  margin-top: -200px;

  @media (max-width: 1024px) {
    text-align: center;
    align-items: center; /* Center content on small screens */
    height: auto;
    margin-top: -30px;
  }
  @media (max-width: 768px) {
    text-align: center;
    align-items: center; /* Center content on small screens */
    margin-top: -30px;
  }
`;

const RightSection = styled.div`
  flex: 1;
  text-align: center;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -200px;

  img {
    width: 90%;
    border-radius: 25px;
  }

  @media (max-width: 768px) {
    text-align: center;
    padding-top: 20px;
    margin-top: 5px;
    img {
    position: relative;
    max-width: 100%;
  }
  }

  @media (max-width: 1024px) {
    height: auto;
    align-items: center;
    padding: 20px;
    margin-top: 35px;
    img {
    position: relative;
  }
  }
`;


const Text = styled.p`
    font-weight: 700;
    font-size: 64px;
    line-height: 68.62px;
    text-align: left;
    color: #FFF8F8;
    @media (max-width: 768px) {
        font-size: 50px;
        margin-top: -10px;
  }
    
`;

const LeftText = styled.div`
    padding-left: 70px; 
    position: absolute;
    z-index: 1;

    @media (max-width: 1024px) {
    position: relative;
    text-align: center;
    align-items: center; /* Center content on small screens */
  }

    @media (max-width: 768px) {
        padding: 20px;
        max-width: 100%;
        position: relative;
  }
    
`;

const Text2 = styled.p`
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    max-width: 721px;
    font-size: 18px;
    color:#B6D2E3;
    @media (max-width: 768px) {
        margin-top: -20px;
    }
`
export const PrimaryButton = styled.button`
  width: 150px;
  height: 50px;
  border-radius: 5px;
  gap: 10px;
  font-size: 16px;
  font-weight: bold;
  background-color: #60CBBE; 
  color: #fff; /* Adjust the text color as needed */
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #4031B7; /* Adjust the hover background color as needed */
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); /* Adjust the focus style as needed */
  }

  &:active {
    background-color: #0056b3; /* Adjust the active background color as needed */
  }
`;
export const SecondaryButton = styled.button`
  width: 150px;
  height: 50px;
  border-radius: 5px;
  gap: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #102128; /* Adjust the text color as needed */
  border: 1px #102128 solid;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;

  &:hover {
    background-color: #102128; /* Adjust the hover background color as needed */
    color: #fff;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); /* Adjust the focus style as needed */
  }
`;

export const HeroContainer2 = styled.div`
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1; /* Place the gradient background behind the content */
  }

  /* Background image */
  background-image: url(${HeroPic2}); /* Set your background image */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  color: #fff; /* Set text color to white or the desired color */
  text-align: center;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow for depth */

  /* Add any additional styles as needed */
`;
const FlexButtons = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
    @media (max-width: 768px) {
        display: flex;
  }
    @media (max-width: 1024px) {
        justify-content: center;
  }

`

const Hero = () => {
    const [show, setShow] = useState(false);
    const handleClosePopup = () => {
    setShow(false); // Hide the popup card
  };
    const navigate = useNavigate()
  return (
    <>
    <HeroContainer>
      <LeftSection>
        <LeftText>
            <Text>Securing the Future<br /> of<span style={{color:'#8CFFF1', fontFamily:'Recoleta'}}> Web3</span></Text>
            <Text2>At CryptoSolution, our core mission is to fortify the decentralized internet, an ecosystem responsible for facilitating transactions valued in trillions of dollars. Our firm stands proudly having safeguarded over $600 Million in digital asset risk, countering the persistent threat of cyber attackers. A testament to our commitment, more than 200 startups, foundations, and enterprises entrust CryptoSolution with the responsibility of ensuring the safety and security of their pioneering products</Text2>
            <FlexButtons>
                <ConnectButton2 />
                <SecondaryButton>
                    Learn More
                </SecondaryButton>
            </FlexButtons>
        </LeftText>
      </LeftSection>
      <RightSection>
        <img src={HeroPic} alt='Hero' style={{ }} />
      </RightSection>
    </HeroContainer>
    </>
  );
};

export default Hero;