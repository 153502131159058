import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import ProquraLogo from '../assets/images/cover.png';
import { FaBars } from 'react-icons/fa';
import { TickerTape } from "react-ts-tradingview-widgets";
import {  useNavigate } from 'react-router-dom';
import ConnectButton from './connectButton';
import { Link as ScrollLink } from 'react-scroll';

const Navbar = styled.nav`
  background: #2615AC;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  position: sticky;
  top: 0;
  z-index: 100;

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`;

export const PrimaryButton = styled.button`
  width: 186px;
  height: 56px;
  padding: 16px 36px;
  border-radius: 100px;
  gap: 10px;
  font-size: 16px;
  font-weight: bold;
  background-color: #102128; 
  color: #fff; /* Adjust the text color as needed */
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #4031B7; /* Adjust the hover background color as needed */
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); /* Adjust the focus style as needed */
  }

  &:active {
    background-color: #0056b3; /* Adjust the active background color as needed */
  }
`;
export const SecondaryButton = styled.button`
  width: 150px;
  height: 50px;
  border-radius: 5px;
  gap: 10px;
  font-size: 16px;
  font-weight: bold;
  background-color: #60CBBE; 
  color: #fff; /* Adjust the text color as needed */
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #4031B7; /* Adjust the hover background color as needed */
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); /* Adjust the focus style as needed */
  }

  &:active {
    background-color: #0056b3; /* Adjust the active background color as needed */
  }
`;

const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 70px;
  background-color: #2615AC;
  color: #B6D2E3;
  border-bottom: 1px #4031B7 solid;

  @media (max-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
  }
`;

const Logo = styled(ScrollLink)`
  width: 146px;
  height: auto;
  text-decoration: none;
  display: none;
  @media (max-width: 1024px) {
    display: flex;
  }
`;

const NavLinks = styled.div`
  display: flex;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const NavLink = styled(ScrollLink)`
  margin: 0 1rem;
  text-decoration: none;
  color: #B6D2E3;
  align-content: center;
  display: grid;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: #e39204;
  }

  &.active {
    color: #e39204;
  }

  @media (max-width: 1024px) {
    margin: 0.5rem 0;
  }
`;

const NavLink2 = styled(Link)`
  margin: 0 1rem;
  text-decoration: none;
  color: #B6D2E3;
  align-content: center;
  display: grid;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: #e39204;
  }

  &.active {
    color: #e39204;
  }

  @media (max-width: 1024px) {
    margin: 0.5rem 0;
  }
`;

const AuthLink = styled.div`
    display: flex;
    align-items: center;
    margin-right: 0px;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 1024px) {
    display: contents;
    position: absolute;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
  }
`;

const Strip = styled.div`
  background-color: #4031B7;
  color: #F2F2F2CC;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  max-width: 100vw;

  @media (max-width: 1024px) {
    justify-content: center;
    padding-right: 0px;
  }
`;

const Nav = ({ toggle }) => {
  const [show, setShow] = useState(false);
  const { pathname } = useLocation();

  const handleClosePopup = () => {
    setShow(false); // Hide the popup card
  };

  const navigate = useNavigate()

  const Ticker = [
    {
      "proName": "FOREXCOM:NSXUSD",
      "title": "US 100"
    },
    {
      "proName": "FX_IDC:EURUSD",
      "title": "EUR to USD"
    },
    {
      "proName": "BITSTAMP:BTCUSD",
      "title": "Bitcoin"
    },
    {
      "proName": "BITSTAMP:ETHUSD",
      "title": "Ethereum"
    },
    {
      "description": "USDT",
      "proName": "BINANCE:BTCUSDT"
    },
    {
      "description": "SOL",
      "proName": "COINBASE:SOLUSD"
    },
    {
      "description": "MATIC",
      "proName": "BINANCE:MATICUSDT"
    },
    {
      "description": "CARDANO",
      "proName": "BINANCE:ADAUSDT"
    },
    {
      "description": "XRP",
      "proName": "BINANCE:XRPUSDT"
    },
    {
      "description": "BNB",
      "proName": "BINANCE:BNBUSDT"
    },
    {
      "description": "Doge",
      "proName": "BINANCE:DOGEUSDT"
    },
    {
      "description": "CHAIN",
      "proName": "BINANCE:LINKUSDT"
    },
    {
      "description": "CAP",
      "proName": "CRYPTOCAP:TOTAL"
    },
    {
      "description": "SHIB",
      "proName": "BINANCE:SHIBUSDT"
    },
    {
      "description": "LUNC",
      "proName": "BINANCE:LUNCUSDT"
    }
  ]
  

  return (
    <>    
      <Navbar id='top'>
        <Strip>
            <TickerTape colorTheme="dark" symbols={Ticker} isTransparent="true"></TickerTape>
        </Strip> 
        <NavbarContainer>
        <Logo to='/' smooth={true} duration={500} spy={true} exact='true' offset={-10000}>
            <img src={ProquraLogo} alt='CryptoSolution Logo' style={{ width: '146px' }} />
        </Logo>
          <NavLinks>
            <NavLink to='top' smooth={true} duration={500} spy={true} exact='true' offset={-10000} style={{marginRight: '190px'}}><img src={ProquraLogo} alt='Logo' style={{ width: '260px', height:'auto' }} /></NavLink>
            <NavLink to="features" smooth={true} duration={500} spy={true} exact='true' offset={-180}>Features</NavLink>
            <NavLink2 to="/airdrops">Airdrops</NavLink2>
            <NavLink to="partners" smooth={true} duration={500} spy={true} exact='true' offset={-100}>Partners</NavLink>
            <NavLink to="testimonials"  smooth={true} duration={500} spy={true} exact='true' offset={-120}>Testimonials</NavLink>
            <NavLink to="contact" smooth={true} duration={500} spy={true} exact='true' offset={-180}>Contact Us</NavLink>
        </NavLinks>
          <AuthLink>
            <ConnectButton />
          </AuthLink>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
        </NavbarContainer>
      </Navbar>
    </>
  );
};

export default Nav;
