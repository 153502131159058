import React, {useEffect} from 'react';
import styled from 'styled-components';
import TokenSight from '../assets/images/tokensight.svg'
import Sovryn from '../assets/images/sovryn.png'
import Math from '../assets/images/mathwallet.png'
import Kusama from '../assets/images/KUSAMA_logo.svg'
import Graph from '../assets/images/thegraph.png'
import Lossl from '../assets/images/lossless.png'
import Brave from '../assets/images/brave.png'
import Rsk from '../assets/images/RBTC-logo.png'
import Radon from '../assets/images/radon.png'
import Shrapnel from '../assets/images/shrapnel.png'
import Debank from '../assets/images/debank.png'
import Cakebot from '../assets/images/cakebot.png'
import Dapp from '../assets/images/dappradar.png'
import Zerion from '../assets/images/zerion.png'
import Trezor from '../assets/images/trezor.png'
import { useLocation } from 'react-router-dom';

const GridContainer = styled.div`
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(2, 1fr);

  @media (min-width: 1024px) {
    grid-template-columns: repeat(5, 1fr);
    gap: 5px;
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }

  margin: 10px;
`;
const GridContainer2 = styled.div`
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(2, 1fr);

  @media (min-width: 1024px) {
    grid-template-columns: repeat(5, 1fr);
    gap: 5px;
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }

  margin: 10px;
`;
const GridContainer3 = styled.div`
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(2, 1fr);

  @media (min-width: 1024px) {
    grid-template-columns: repeat(5, 1fr);
    gap: 5px;
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }

  margin: 10px;
`;

const ImageContainer = styled.div`
  margin: 5px;
  justify-content: center;
  display: flex;
  padding: 10px;
  width: 170px;
  height: 100px;
  border: 1px solid grey;
  border-radius: 10px;
  overflow: hidden; /* Ensure images do not overflow the container */

  img {
    max-width: 100%; /* Scale down the image if it's larger than the container width */
    max-height: 100%; /* Scale down the image if it's taller than the container height */
    object-fit: contain; /* Maintain the aspect ratio without stretching */
    transition: filter 0.3s ease; /* Add a transition effect on hover */

    &:hover {
      filter: none;
      transform: scale(1.1);
    }
  }
`;


const images = [
  { src: Kusama, alt: 'Kusama' },
  { src: Graph, alt: 'The Graph' },
  { src: Lossl, alt: 'losseless' },
  { src: Brave, alt: 'Brave' },
  { src: Rsk, alt: 'Rootstock' },
];
const images2 = [
  { src: Radon, alt: 'Radon' },
  { src: Shrapnel, alt: 'Shrapnel' },
  { src: Debank, alt: 'Debank' },
  { src: Cakebot, alt: 'Cakebot' },
  { src: TokenSight, alt: 'applepay' },
];
const images3 = [
  { src: Sovryn, alt: 'Sovryn' },
  { src: Math, alt: 'Math Wallet' },
  { src: Dapp, alt: 'DappRadar' },
  { src: Zerion, alt: 'Zerion' },
  { src: Trezor, alt: 'Trezor' },
];

const Partners = () => {

  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      // Remove '#' from the hash
      const sectionId = hash.substring(1);
      const element = document.getElementById(sectionId);

      if (element) {
        // Scroll to the corresponding section
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [hash]);

  return (
    <>
        <div id="partners" style={{textAlign:'center', paddingTop:'4%', paddingBottom:'2%', color:'#000', fontWeight:'700', fontSize:'40px', lineHeight:'48.41px'}}>
            Leading Web3 Projects Work With CryptoSolutions
        </div>
        <GridContainer>
        {images.map((image, index) => (
            <ImageContainer key={index}>
            <img className="image" src={image.src} alt={image.alt} />
            </ImageContainer>
        ))}
        </GridContainer>
        <GridContainer2>
        {images2.map((image, index) => (
            <ImageContainer key={index}>
            <img className="image" src={image.src} alt={image.alt} />
            </ImageContainer>
        ))}
        </GridContainer2>
        <GridContainer3>
        {images3.map((image, index) => (
            <ImageContainer key={index}>
            <img className="image" src={image.src} alt={image.alt} />
            </ImageContainer>
        ))}
        </GridContainer3>
    </>
    
  );
};

export default Partners;
