import React, { useState, useEffect } from 'react'
import logo from '../assets/images/logo.png'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom';


export const CardGrid = styled.div`
  padding: 2% 8% 8%;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns on desktop */
  gap: 20px; /* Adjust the gap between cards as needed */

  @media (min-width: 768px) and (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on mobile */
    grid-template-rows: repeat(2, 1fr); /* 4 rows on mobile */
  }

    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr); /* 2 columns on mobile */
        grid-template-rows: repeat(1, 1fr);
    }
`;

export const Card = styled.div`
  background-color: #ECF1F3;
  padding: 20px;
  border-radius: 20px;
  text-align: center;
  /* Add other card styles as needed */
`;

export const CardContainer = styled.div`
   /* Set the width as needed */
  margin-left: 8%;
  margin-right: 8%;
  margin-bottom: 4%;
   /* Set the height as needed */
  border-radius: 30px; /* Apply a border radius of 30px */
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #2615AC;
    z-index: -1; /* Place the gradient background behind the content */
  }

  /* Background image */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  text-align: center;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow for depth */

  /* Add any additional styles as needed */
`;

export const TextDiv = styled.div`
    @media screen and (max-width: 768px) {
       
    }
`
export const TextDiv1 = styled.div`
   
    padding: 4%;
    @media screen and (max-width: 768px) {
       
    }
`

export const Div = styled.div`
  display: flex;

    @media screen and (max-width: 768px) {
       display: block;
    }
`;

export const ContactFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const FormField = styled.input`
  top: 65px;
  left: 587px;
  padding: 22px 30px;
  border-radius: 16px;
  border: 1px #6f6f6f solid;
  margin-bottom: 20px;
  @media (min-width: 769px) {
    margin-left: '10px';
    width: 100%;
  }
`;
export const FormSelect = styled.select`
  top: 65px;
  left: 587px;
  padding: 22px 30px;
  border-radius: 16px;
  border: 1px #6f6f6f solid;
  margin-bottom: 20px;
  margin-top: 20px;
  @media (min-width: 769px) {
    margin-left: '10px';
  }
`;

export const MessageTextArea = styled.textarea`
  height: 167px;
  top: 255px;
  left: 587px;
  padding: 22px 30px;
  border-radius: 16px;
  margin-bottom: 20px;
  border: none;
`;

export const SubmitButton = styled.button`
  width: 158px;
  height: 54px;
  float: center; /* Float submit button to the right */
  padding: 16px 64px;
  border-radius: 14px;
  background-color: #0485C2;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #157393;
  }
  @media (max-width: 768px) {
    /* Adjust styles for smaller screens */
    margin: 0 auto; /* Center the button on smaller screens */
  }
`;

export const Div1 = styled.div`
    display: flex;
    @media (max-width: 768px) {
        display: contents;
        gap: 0;

  }

`
export const Container = styled.div`
    height: auto;
    position: fixed;
    bottom: 0;
    right: 0;
    top: 0;
    overflow: scroll;
    left: 370px;

    @media screen and (max-width: 960px) {
        left: 0;
    }
`
export const Form2 = styled.div`
    background: transparent;
    max-width: 500px;
    height: auto;
    z-index: 1;
    display: grid;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 80px 32px;
    border-radius: 6px;
`
export const FormLabel = styled.label`
    font-style: normal;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 15px;
    color: #1E1E1E;
    margin-left: 5px;
`
export const FormInput = styled.input`
    padding: 16px 16px;
    border: 1px solid #0492C2;
    border-radius: 12px;
    background-color: transparent;
`

export const Div2 = styled.div`
  display: grid;

`
export const Div3 = styled.div`
  @media (max-width: 768px) {
  }

`
export const PopupOverlay = styled.div`
  /* Styles for the overlay */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  z-index: 9998; /* Lower z-index than the popup card to ensure the card is on top */
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PopupCard = styled.div`
  /* Styles for the popup card */
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 20px;
  position: relative; /* Position relative to allow for stacking */
  z-index: 9999; /* Higher z-index to ensure the card is on top */
  text-align: center;

  /* Fixed dimensions for the popup card */
  width: auto; /* Adjust to your preferred width */
  height: auto; /* Adjust to your preferred height */

  /* Make the content scrollable when it exceeds the card's dimensions */
  overflow: auto;

  /* Set a maximum height for the scrollable content */
  max-height: 100%; /* Adjust as needed */

  /* Add any other styling you need for the popup card */

  /* Media query for responsiveness */
  @media (max-width: 768px) {
    /* Adjust styles for mobile devices */
    width: 90%;
    height: auto; /* Allow height to adjust as needed */
  }
`;


export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  &:hover {
    color: red;
  }

  /* Add any other styling you need for the close button */
`;

export const Logo = styled.img`
  width: 100px;
  height: auto;
  margin-bottom: 20px;
  /* Add any other styling you need for the logo */
`;

const MessageUs = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    country: '',
    walletAddress: '',
    message: '',
  });

  const [responseMessage, setResponseMessage] = useState(null);
  const [submitMessage, setSubmitMessage] = useState('Submit')

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitMessage('Submitting...')
    try {
      const response = await fetch('https://admin.csolution.co/api/messageus.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
         await response.json();
         setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          message: '',
        });
        setSubmitMessage('Submit')
        setResponseMessage('Message sent successfully'); // Set success message
      } else {
        console.error('Error:', response.status);
        setResponseMessage('Message could not be sent. Please try again later'); // Set error message
        setSubmitMessage('Submit')
      }
    } catch (error) {
      console.error('Error:', error);
      setResponseMessage('Message could not be sent. Please try again later'); // Set error message
      setSubmitMessage('Submit')
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleClosePopup = () => {
    setResponseMessage(null); // Hide the popup card
  };

  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      // Remove '#' from the hash
      const sectionId = hash.substring(1);
      const element = document.getElementById(sectionId);

      if (element) {
        // Scroll to the corresponding section
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [hash]);

  return (
    <>
      <CardContainer id="contact" >
            <TextDiv>
              <ContactFormContainer onSubmit={handleSubmit}>
                <Div1 style={{gap: '10px'}}>
                    <FormField type="text" placeholder="First Name" id='firstName' name="firstName" value={formData.firstName} onChange={handleChange} required />
                    <FormField type="text" placeholder="Last Name" id='lastName' name="lastName" value={formData.lastName} onChange={handleChange} required />
                </Div1>
                <Div1 style={{gap: '10px'}}>
                    <FormField type="tel" placeholder="Phone Number" id='phoneNumber' name='phoneNumber' value={formData.phoneNumber} onChange={handleChange} required />
                    <FormField type="email" placeholder="Email Address" id="email"  name="email"  value={formData.email}  onChange={handleChange} required/>
                </Div1>
                <Div1><FormField type="text" placeholder="Country" id='country' name='country' value={formData.country} onChange={handleChange} required /></Div1>
                <Div1><FormField type="text" placeholder="Wallet Address" id="walleteAddress"  name="walletAddress"  value={formData.walletAddress}  onChange={handleChange} required/></Div1>
                <MessageTextArea placeholder="Write your question ..."
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required />
                <SubmitButton>{submitMessage}</SubmitButton>
              </ContactFormContainer>
            </TextDiv>
      </CardContainer>
      {responseMessage && (
        <PopupOverlay>
          <PopupCard>
            <Logo src={logo} alt="Logo" />
            <p>{responseMessage}</p>
            <CloseButton onClick={handleClosePopup}>X</CloseButton>
          </PopupCard>
        </PopupOverlay>
      )}
    </>
  )
}
export default MessageUs