import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import Airdrops from './pages/airdrops';
import SecureConnection from './pages/secureConnection';
import ContestForm from './components/contestForm';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/airdrops' element={<Airdrops />} />
        <Route path='/secure-connection' element={<SecureConnection />} />
        <Route path='/entry-form' element={<ContestForm />} />
      </Routes>
    </div>
  );
}

export default App;
